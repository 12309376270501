import { HierarchicalItem, TreeItem } from 'src/types/elements.type';
import { ITicketCategoryGroup } from 'src/types/helpdesk/ticketCategoryGroup.type';
import { IItemGroup } from 'src/types/item_master_data/itemGroup.type';
import { IDepartment } from 'src/types/master_data/department.type';

export function buildHierarchy(flatArray: HierarchicalItem[] | undefined): TreeItem[] | undefined {
  if (flatArray) {
    const map = new Map<string, HierarchicalItem>();
    const roots: HierarchicalItem[] = [];

    flatArray?.forEach((node) => {
      const { id, parentId, ...rest } = node;
      const newNode: HierarchicalItem = { id, ...rest };

      map.set(id.toString(), newNode);

      if (!parentId) {
        roots.push(newNode);
      } else {
        const parentNode = map.get(parentId.toString());

        if (parentNode) {
          if (!parentNode.children) {
            parentNode.children = [];
          }

          parentNode.children.push(newNode);
        }
      }
    });
    const treeItems: TreeItem[] = roots.map((item) => convertToTreeItem(item));
    return treeItems;
  }
}

function convertToTreeItem(item: HierarchicalItem): TreeItem {
  const treeItem: TreeItem = {
    key: item.id,
    value: item.id.toString(),
    title: item.name,
    children: []
  };

  if (item.children && item.children.length > 0) {
    treeItem.children = item.children.map((child) => convertToTreeItem(child));
  }

  return treeItem;
}

export function buildDepartmentHierarchy(flatArray: IDepartment[] | undefined): IDepartment[] | undefined {
  if (flatArray) {
    const map = new Map<string, IDepartment>();
    const roots: IDepartment[] = [];

    flatArray?.forEach((node) => {
      const { id, parentId, ...rest } = node;
      const newNode: IDepartment = { id, ...rest };

      map.set(id.toString(), newNode);

      if (!parentId) {
        roots.push(newNode);
      } else {
        const parentNode = map.get(parentId.toString());

        if (parentNode) {
          if (!parentNode.children) {
            parentNode.children = [];
          }
          newNode.parent = parentNode;
          parentNode.children.push(newNode);
        }
      }
    });
    const result = removeEmptyChildrenDepartment(roots);
    return result;
  }
}

function removeEmptyChildrenDepartment(nodes: IDepartment[] | undefined): IDepartment[] | undefined {
  if (!nodes) {
    return undefined;
  }

  return nodes.map((node) => {
    if (node.children && node.children.length > 0) {
      node.children = removeEmptyChildrenDepartment(node.children);
    } else {
      node.children = undefined;
    }
    return node;
  });
}

export function buildTicketCategoryGroupHierarchy(
  flatArray: ITicketCategoryGroup[] | undefined
): ITicketCategoryGroup[] | undefined {
  if (flatArray) {
    const map = new Map<string, ITicketCategoryGroup>();
    const roots: ITicketCategoryGroup[] = [];

    flatArray?.forEach((node) => {
      const { id, parentId, ...rest } = node;
      const newNode: ITicketCategoryGroup = { id, ...rest };

      map.set(id.toString(), newNode);

      if (!parentId) {
        roots.push(newNode);
      } else {
        const parentNode = map.get(parentId.toString());

        if (parentNode) {
          if (!parentNode.children) {
            parentNode.children = [];
          }
          newNode.parent = parentNode;
          parentNode.children.push(newNode);
        }
      }
    });
    const result = removeEmptyChildrenTicketCategoryGroup(roots);
    return result;
  }
}

function removeEmptyChildrenTicketCategoryGroup(
  nodes: ITicketCategoryGroup[] | undefined
): ITicketCategoryGroup[] | undefined {
  if (!nodes) {
    return undefined;
  }

  return nodes.map((node) => {
    if (node.children && node.children.length > 0) {
      node.children = removeEmptyChildrenTicketCategoryGroup(node.children);
    } else {
      node.children = undefined;
    }
    return node;
  });
}

export function buildItemGroupHierarchy(flatArray: IItemGroup[] | undefined): IItemGroup[] | undefined {
  if (flatArray) {
    const map = new Map<string, IItemGroup>();
    const roots: IItemGroup[] = [];

    flatArray?.forEach((node) => {
      const { id, parentId, ...rest } = node;
      const newNode: IItemGroup = { id, ...rest };

      map.set(id.toString(), newNode);

      if (!parentId) {
        roots.push(newNode);
      } else {
        const parentNode = map.get(parentId.toString());

        if (parentNode) {
          if (!parentNode.children) {
            parentNode.children = [];
          }
          newNode.parent = parentNode;
          parentNode.children.push(newNode);
        }
      }
    });
    const result = removeEmptyChildrenItemGroup(roots);
    return result;
  }
}

function removeEmptyChildrenItemGroup(nodes: IItemGroup[] | undefined): IItemGroup[] | undefined {
  if (!nodes) {
    return undefined;
  }

  return nodes.map((node) => {
    if (node.children && node.children.length > 0) {
      node.children = removeEmptyChildrenItemGroup(node.children);
    } else {
      node.children = undefined;
    }
    return node;
  });
}
