import { useQuery } from '@tanstack/react-query';
import { TreeSelect } from 'antd';
import { useEffect, useState } from 'react';
import { getDepartmentsDropdown } from 'src/apis/master_data/department.api';
import { TreeItem } from 'src/types/elements.type';
import { buildHierarchy } from 'src/utils/collection';

interface Props {
  value?: string[];
  style?: React.CSSProperties | undefined;
  allowClear?: boolean | undefined;
  onChange?: (value: string[]) => void;
  disabled?: boolean;
  treeCheckable?: boolean;
  className?: string;
  placeholder?: string;
}

export default function DepartmentTreeMultipleSelect({
  value,
  style,
  allowClear = true,
  onChange,
  disabled,
  treeCheckable,
  className,
  placeholder
}: Props) {
  const [departmentTreeItem, setDepartmentTreeItem] = useState<TreeItem[] | undefined>(undefined);

  const departmentsDropdownQuery = useQuery({
    queryKey: ['departmentsTreeDropdown'],
    queryFn: () => getDepartmentsDropdown(),
    staleTime: 5 * 60 * 1000
  });

  useEffect(() => {
    const result = buildHierarchy(departmentsDropdownQuery.data?.data);
    setDepartmentTreeItem(result);
  }, [departmentsDropdownQuery.data?.data]);

  return (
    <TreeSelect
      treeLine={true}
      value={departmentsDropdownQuery.isFetching ? undefined : value}
      disabled={disabled}
      showSearch
      placeholder={placeholder}
      className={className}
      style={style}
      treeCheckable={treeCheckable}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      treeNodeFilterProp='value'
      allowClear={allowClear}
      onChange={onChange}
      multiple
      maxTagCount='responsive'
      treeData={departmentTreeItem}
      loading={departmentsDropdownQuery.isFetching}
      filterTreeNode={(inputValue, treeNode) => {
        return treeNode.title?.toString().toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()) ?? false;
      }}
    />
  );
}
