import { useState, useEffect, ReactNode } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { TreeItem } from 'src/types/elements.type';
import { buildHierarchy } from 'src/utils/collection';
import { getDepartmentsDropdown } from 'src/apis/master_data/department.api';

export type OptionType = {
  value: number;
  label: string;
};
interface Props {
  value?: number | undefined;
  style?: React.CSSProperties | undefined;
  allowClear?: boolean | undefined;
  onChange?: (value: number, option: OptionType | OptionType[]) => void;
  disabled?: boolean;
  placeholder?: ReactNode;
}

export default function DepartmentSelect({ value, style, allowClear, onChange, disabled, placeholder }: Props) {
  const [departmentTreeItem, setDepartmentTreeItem] = useState<TreeItem[] | undefined>(undefined);

  const departmentsDropdownQuery = useQuery({
    queryKey: ['departmentsDropdown'],
    queryFn: () => getDepartmentsDropdown(),
    staleTime: 5 * 60 * 1000
  });

  useEffect(() => {
    const result = buildHierarchy(departmentsDropdownQuery.data?.data);
    setDepartmentTreeItem(result);
  }, [departmentsDropdownQuery.data?.data]);

  return (
    <Select
      allowClear={allowClear}
      value={departmentsDropdownQuery.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      disabled={disabled}
      placeholder={placeholder}
      options={departmentTreeItem?.map((data) => ({
        value: Number(data.value),
        label: data.title
      }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={departmentsDropdownQuery.isFetching}
    />
  );
}
