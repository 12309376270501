import { useQuery } from '@tanstack/react-query';
import { TreeSelect } from 'antd';
import { useEffect, useState } from 'react';
import { getTicketCategoryGroupsDropdown } from 'src/apis/helpdesk/ticketCategoryGroup.api';
import { TreeItem } from 'src/types/elements.type';
import { buildHierarchy } from 'src/utils/collection';

interface Props {
  value?: number[] | number;
  onChange?: (value: number[] | number) => void;
  style?: React.CSSProperties | undefined;
  allowClear?: boolean;
  departmentId?: number | undefined;
  className?: string;
  placeholder?: string;
  multiple?: boolean;
}

export default function TicketCategoryGroupTreeSelect({
  value,
  onChange,
  style,
  allowClear = true,
  departmentId,
  className,
  placeholder,
  multiple
}: Props) {
  const [data, setData] = useState<TreeItem[] | undefined>(undefined);

  const ticketCategoryGroupsDropdownQuery = useQuery({
    queryKey: ['ticketCategoryGroupsDropdown', departmentId],
    queryFn: () => getTicketCategoryGroupsDropdown(departmentId)
  });

  useEffect(() => {
    if (ticketCategoryGroupsDropdownQuery.data?.data) {
      const dataHierarchy = buildHierarchy(ticketCategoryGroupsDropdownQuery?.data?.data);
      setData(dataHierarchy);
    }
  }, [ticketCategoryGroupsDropdownQuery.data?.data]);

  return (
    <TreeSelect
      treeLine={true}
      multiple={multiple}
      allowClear={allowClear}
      value={ticketCategoryGroupsDropdownQuery.isFetching ? undefined : value}
      style={style}
      className={className}
      placeholder={placeholder}
      onChange={onChange}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      treeNodeFilterProp='name'
      showSearch
      loading={ticketCategoryGroupsDropdownQuery.isFetching}
      treeData={data}
      filterTreeNode={(inputValue, treeNode) => {
        return treeNode.title?.toString().toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()) ?? false;
      }}
    />
  );
}
