import { useQuery } from '@tanstack/react-query';
import { TreeSelect } from 'antd';
import { useEffect, useState } from 'react';
import { getDepartmentsAvailableRaise } from 'src/apis/master_data/userTicketCategory.api';
import { TreeItem } from 'src/types/elements.type';
import { buildHierarchy } from 'src/utils/collection';

interface Props {
  value?: number | undefined;
  style?: React.CSSProperties | undefined;
  allowClear?: boolean | undefined;
  onChange?: (value: number | undefined, label: React.ReactNode[]) => void;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
  ticketCategoryId: number;
  isShowsOptionNull?: boolean;
}

export default function DepartmentAvailableRaiseSelect({
  value,
  style,
  allowClear = true,
  onChange,
  disabled,
  className,
  placeholder,
  ticketCategoryId,
  isShowsOptionNull
}: Props) {
  const [departmentTreeItem, setDepartmentTreeItem] = useState<TreeItem[] | undefined>(undefined);

  const departmentsDropdownQuery = useQuery({
    queryKey: ['departmentsAvailableRaiseDropdown'],
    queryFn: () => getDepartmentsAvailableRaise(ticketCategoryId),
    staleTime: 60 * 1000 * 60
  });

  useEffect(() => {
    const result = buildHierarchy(departmentsDropdownQuery.data?.data);
    if (result && isShowsOptionNull) {
      result?.unshift({
        key: 0,
        value: '0',
        title: 'Không có bộ phận',
        children: []
      });
    }

    setDepartmentTreeItem(result);
  }, [departmentsDropdownQuery.data?.data, isShowsOptionNull]);

  return (
    <TreeSelect
      treeLine={true}
      value={departmentsDropdownQuery.isFetching ? undefined : value}
      disabled={disabled}
      showSearch
      placement='bottomLeft'
      placeholder={placeholder}
      className={className}
      style={style}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      treeNodeFilterProp='value'
      allowClear={allowClear}
      onChange={onChange}
      treeData={departmentTreeItem}
      loading={departmentsDropdownQuery.isFetching}
      filterTreeNode={(inputValue, treeNode) => {
        return treeNode.title?.toString().toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()) ?? false;
      }}
    />
  );
}
