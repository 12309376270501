import { RightOutlined, SearchOutlined } from '@ant-design/icons';
import { Empty, Flex, Input, List, Typography } from 'antd';
import { useState } from 'react';
import { TreeItem } from 'src/types/elements.type';
import './TicketCategoryGroupList.scss';
const { Text } = Typography;

type Props = {
  loading: boolean;
  items: TreeItem[] | undefined;
  onChange: (value: number | string) => void;
};

export default function TicketCategoryGroupList({ loading, items, onChange }: Props) {
  const [history, setHistory] = useState([{ data: items }]);
  const current = history[history.length - 1];

  return (
    <Flex vertical gap={8} className='ticket-category-group'>
      <Input
        placeholder='Tìm kiếm danh mục ticket'
        size='middle'
        allowClear
        suffix={
          <SearchOutlined
            style={{
              fontSize: 16,
              color: '#1677ff'
            }}
          />
        }
      />
      <List
        dataSource={current?.data}
        loading={loading}
        className='ticket-category-group-list'
        locale={{
          emptyText: (
            <Empty
              image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
              imageStyle={{ height: 100 }}
              description='Không có kết quả được tìm thấy'
            />
          )
        }}
        renderItem={(itemChild) => {
          const isParent = !!itemChild.children && itemChild.children.length > 0;

          return (
            <List.Item
              key={itemChild.key}
              className='ticket-category-group-item'
              onClick={() => {
                if (isParent) {
                  setHistory((prev) => [...prev, { data: itemChild.children }]);
                } else {
                  onChange(itemChild.key);
                }
              }}
            >
              <List.Item.Meta title={<Text style={{ fontWeight: 500 }}>{itemChild.title}</Text>} />
              {isParent && (
                <Text type='secondary'>
                  <RightOutlined />
                </Text>
              )}
            </List.Item>
          );
        }}
      />
    </Flex>
  );
}
